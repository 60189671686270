// ** Reactstrap Imports
import { Row, Col, UncontrolledTooltip, Button, CardText } from 'reactstrap'

// ** Styles
import '@styles/react/pages/page-authentication.scss'
import logo from '@src/assets/images/logo/logo_white_horizontal.svg'
import { Home, UserPlus, Phone, UserCheck } from 'react-feather'

import { useEffect, useRef, useState } from 'react'

import Wizard from '@src/components/stepper'
import NewAcc from './RegisterWithInvite/NewAcc'
import Address from './RegisterWithInvite/Address'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Veriff from './ContractorRegister/Veriff'
import PhoneVerification from './RegisterWithInvite/PhoneVerification'
import { SpinnerLoader, IntlDropdown } from '@Intelli/utilities'
import Document from './ContractorRegister/Document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import APIService from '@src/services/API'
import { faPersonFromPortal } from '@fortawesome/pro-regular-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

const stepSkipMap = {
  hasPassword: 1,
  phone: 2,
  address: 3,
  hasCurriculum: 4,
  hasBeenVerified: 5
}

const RegisterContractor = ({ data, location }) => {
  // ** Ref
  const ref = useRef(null)
  const { t } = useTranslation()
  const API = new APIService()
  const navigate = useNavigate()

  // ** State
  const [stepper, setStepper] = useState()
  const [registerData, setRegisterData] = useState({})
  const [currentIndex, setCurrentIndex] = useState(0)

    const { mutate: finishInivte } = useMutation(() => API.FinishInvitation(), {
      onSuccess: () => {
        navigate('/lobby')
      }
    })

  const steps = [
    {
      id: 'new-account',
      // title: 'Account',
      title: t('register.backoffice.Step-Account', { ns: 'modules' }),
      subtitle: '',
      icon: <UserPlus size={18} />,
      content: (
        <NewAcc
          stepper={stepper}
          data={data}
          setRegisterData={setRegisterData}
        />
      )
    },
    {
      id: 'verify-phone',
      // title: 'Phone',
      title: t('register.backoffice.Step-Phone', { ns: 'modules' }),
      subtitle: '',
      icon: <Phone size={18} />,
      content: (
        <PhoneVerification
          stepper={stepper}
          data={data}
          setCurrentIndex={setCurrentIndex}
          location={location}
        />
      )
    },
    {
      id: 'address',
      // title: 'Address',
      title: t('register.backoffice.Step-Address', { ns: 'modules' }),
      subtitle: '',
      icon: <Home size={18} />,
      content: (
        <Address
          index={2}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          stepper={stepper}
          data={data ? data : {}}
          location={location}
        />
      )
    },
    {
      id: 'legal-document',
      // title: 'CV',
      title: t('register.contractor.Step-CV', { ns: 'modules' }),
      subtitle: '',
      icon: <FontAwesomeIcon icon={faFileArrowUp} size="lg" />,
      content: (
        <Document
          stepper={stepper}
          data={data}
          currentIndex={currentIndex}
          finishInivte={finishInivte}
        />
      )
    },
    {
      id: 'verify-document',
      // title: 'Identity',
      title: t('register.backoffice.Step-Identity', { ns: 'modules' }),
      subtitle: '',
      icon: <UserCheck size={18} />,
      content: <Veriff data={data} registerData={registerData} />
    }
  ]




  useEffect(() => {
    if (stepper && data) {
      let stepToSkip = 0
      for (const key of Object.keys(data)
        .filter(key => !!stepSkipMap[key])
        .sort((a, b) => stepSkipMap[a] - stepSkipMap[b])) {
        if (data[key] && stepSkipMap[key] > stepToSkip) {
          if(stepSkipMap[key] === 2 && !data[key].verified){
            stepToSkip = 1
            break
          }
          if (stepSkipMap[key] === 5 && data['hasCurriculum'] === true) {
            finishInivte()
          } else if (
            stepSkipMap[key] === 5 &&
            data['hasCurriculum'] === false
          ) {
            stepToSkip = 3
            break
          } else {
            

            stepToSkip = stepSkipMap[key]
          }
        }
      }

      if (stepToSkip > 0) {
        stepper.to(stepToSkip + 1)
      }
    }
  }, [stepper, data])

  if (!data) {
    return <SpinnerLoader />
  }

  return (
    <div className="auth-wrapper auth-cover  d-flex justify-content-center ">
      <div>
        <CardText className="text-end nav-buttons" style={{ minWidth: 100 }}>
          <ul className="nav navbar-nav align-items-center ms-auto">
            <IntlDropdown />
          </ul>
        </CardText>
      </div>
      <Row className="auth-inner m-0">
        <img
          style={{ maxWidth: '250px' }}
          src={logo}
          alt="logo"
          className="brand-logo"
        />

        <Col lg="3" className="d-none d-lg-flex align-items-center p-0">
          <div className="w-100 d-lg-flex align-items-center justify-content-center register-img" />
        </Col>
        <Col lg="9" sm="11" className="auth-bg px-2 px-sm-3 px-lg-5">
          <Wizard
            ref={ref}
            steps={steps}
            instance={el => setStepper(el)}
            headerClassName="d-flex justify-content-center"
            contentWrapperClassName="px-0 mt-2"
            className="register-multi-steps-wizard shadow-none h-100"
            activeIndex={currentIndex}
            setActiveIndex={setCurrentIndex}
          />
        </Col>
      </Row>
    </div>
  )
}

export default RegisterContractor
