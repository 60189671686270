// ** React Imports
import { Fragment, lazy } from 'react'
import { Navigate } from 'react-router-dom'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'

// ** Utils
import { isObjEmpty } from '@utils'
import CompanyPicker from '../../views/Lobby'
import Error from '@src/views/Error'
import Register from '@src/views/Register'
import SignRedirect from '@src/views/sign/SignRedirect'


// import LegalRepresent from "@src/views/LegalRepresent"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = '%s - Joobpay'

/**
 * PAYMENT
 */

// ** Default Route
const DefaultRoute = '/auth'
const PaymentView = lazy(() => import('../../views/payment/view'))

const PaymentSuccessed = lazy(() =>
  import('../../views/payment/PaymentSuccessed')
)
const Login = lazy(() => import('../../views/Login'))
const RegisterWithInvite = lazy(() => import('../../views/RegisterWithInvite'))
const RegisterAdmin = lazy(() => import('../../views/RegisterAdmin'))
const ForgotPassword = lazy(() => import('../../views/ForgotPassword'))
const PhoneVerify = lazy(() => import('../../views/PhoneVerify'))
const ResetPassword = lazy(() => import('../../views/ResetPassword'))
const LegalRepresent = lazy(() => import('../../views/LegalRepresent'))
const ContractSign = lazy(() => import('../../views/ContractSign'))
const LicenseCreation = lazy(() => import('../../views/LicenseCreation'))

// ** Merge Routes
const Routes = [
  {
    path: '/',
    element: <Navigate to="/auth/login" />
  },
  {
    path: '/payment',
    element: <PaymentView />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/payment/successed',
    element: <PaymentSuccessed />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/auth/login',
    element: <Login />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/auth/login',
    element: <Login />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/register/invite',
    element: <Register />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/auth/forgot-password',
    element: <ForgotPassword />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/auth/reset-password',
    element: <ResetPassword />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/auth/verify-phone',
    element: <PhoneVerify />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/lobby',
    element: <CompanyPicker />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/contract/finish-sign',
    element: <ContractSign />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/contract/sign-redirection',
    element: <SignRedirect />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/404',
    element: <Error />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '*',
    element: <Error />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/auth/legal-representative/:id',
    element: <LegalRepresent />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/auth/license-creation',
    element: <LicenseCreation />,
    meta: {
      layout: 'blank'
    }
  }
]

const getRouteMeta = route => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter(route => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = layout => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const AllRoutes = []

  layouts.forEach(layoutItem => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
