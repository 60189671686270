import { createSlice } from "@reduxjs/toolkit"


export const emailSlice =  createSlice(
    {
        name: 'settings',
        initialState: {
            email:''
        },
        reducers:{
            setUsrEmail: (state, action) => {
                if (action.type === 'settings/setUsrEmail') {
                    state.email = action.payload
                }
            }
        }
    }
)

export const {setUsrEmail} = emailSlice.actions

export default emailSlice.reducer