// ** React Imports
import { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// ** Third Party Components
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Reactstrap Imports
import { Form, Row, Button, Modal, UncontrolledTooltip, Col } from 'reactstrap'

// ** Custom Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import APIService from '@src/services/API'
import moment from 'moment-timezone'
import { TimerButton, JpButton } from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons'

const MySwal = withReactContent(Swal)

const TwoFactorModal = ({ isModalOpen, refetch }) => {
  const { t } = useTranslation()

  const SignupSchema = yup.object().shape({
    vcode_2: yup.string().required('Text-6Digits'),
    vcode_3: yup.string().required('Text-6Digits'),
    vcode_1: yup.string().required('Text-6Digits'),
    vcode_4: yup.string().required('Text-6Digits'),
    vcode_5: yup.string().required('Text-6Digits'),
    vcode_6: yup.string().required('Text-6Digits')
  })

  const API = new APIService()

  // ** Hooks

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SignupSchema)
  })

  const [tokenId, setTokenId] = useState('')
  const [expires, setExpires] = useState({})
  const [isDisabled, setIsDisabled] = useState(false)

  const handleWarning = (warningTitle, warningText) => {
    return MySwal.fire({
      title: warningTitle,
      text: warningText,
      icon: 'warning',
      confirmButtonText: 'Go back',
      customClass: {
        confirmButton: 'btn btn-warning'
      },
      buttonsStyling: false
    })
  }


  const { mutate: sendToken } = useMutation(() => API.send2faCode(), {
    onSuccess: response => {
      reset()
      const expiredStamp = moment(response.expiration).diff(moment())
      setExpires({
        hours: 0,
        minutes: moment(expiredStamp).minutes(),
        seconds: moment(expiredStamp).seconds()
      })
      setTokenId(response.token_id)
      setIsDisabled(true)
      setTimeout(() => {
        setIsDisabled(false)
      }, expiredStamp)
    }
  })

  const { mutate: setSessionTwoFactor } = useMutation(
    token => API.set2fa(token),
    {
      onSuccess: () => {
        refetch()
      }
    }
  )
  const { mutate: TwoFactorValidate } = useMutation(
    token => API.validate2faCode(token),
    {
      onSuccess: response => {
        setSessionTwoFactor({ two_factor_id: response.two_factor_id })
      }
    }
  )

  const onSubmit = data => {
    const code =
      getValues('vcode_1') +
      getValues('vcode_2') +
      getValues('vcode_3') +
      getValues('vcode_4') +
      getValues('vcode_5') +
      getValues('vcode_6')
    if (tokenId !== '') {
      TwoFactorValidate({ token_id: tokenId, token: code })
    } else {
      handleWarning(
        'Something is wrong',
        'Please press the get code button and set the correct code on the field'
      )
    }
  }

  const handleOtpPaste = e => {
    const otp_inputs = document.querySelectorAll('.auth-input-wrapper input')
    const paste = (e.clipboardData || window.clipboardData).getData('text')
    const value = paste.split('')
    if (value.length === 6) {
      otp_inputs.forEach((input, index) => {
        input.value = value[index]
        setValue(`vcode_${index + 1}`, value[index])
      })
    }
  }

  const handleOtp = e => {
    const input = e.target
    const value = input.value
    const field_index = input.tabIndex

    input.value = value ? value[0] : ''

    if (value.length > 0 && field_index < 5) {
      input.nextElementSibling.focus()
    }

    if (e.key === 'Backspace' && field_index > 0) {
      input.previousElementSibling.focus()
    }

    setValue(`vcode_${field_index + 1}`, value)
  }

  const otpError = Object.keys(errors).some(
    key => key.includes('vcode') && errors[key].message
  )

  return (
    <Modal isOpen={isModalOpen} backdrop={'static'} centered>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-end align-items-center">
          <Col md="2" className="text-end">
            <UncontrolledTooltip placement="bottom" target="logout">
              {t('login.logout', { ns: 'modules' })}
            </UncontrolledTooltip>
            <Button
              id="logout"
              color="link"
              onClick={() => {
                API.logout()
              }}
            >
              <FontAwesomeIcon icon={faPowerOff} size="2x" />
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mt-3 p-3 pt-1 pb-0">
          <h6>{t('title.Title-2fa', { ns: 'modules' })}</h6>
          <p>
            {t('title.Subtitle-2fa', { ns: 'modules' })}{' '}
            <i>{t('validation.Text-GetCode', { ns: 'modules' })}</i>
          </p>
          <small>{t('validation.Text-TypeCode', { ns: 'modules' })}</small>
          <div className="auth-input-wrapper d-flex align-items-center justify-content-center mt-3">
            <input
              {...register('vcode_1')}
              tabIndex={0}
              onKeyUp={handleOtp}
              onPaste={handleOtpPaste}
              autocomplete="off"
              type="tel"
              maxLength="1"
              className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                otpError ? 'border-danger' : ''
              }`}
            />
            <input
              {...register('vcode_2', { required: true })}
              tabIndex={1}
              onKeyUp={handleOtp}
              onPaste={handleOtpPaste}
              type="tel"
              autocomplete="off"
              maxLength="1"
              className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                otpError ? 'border-danger' : ''
              }`}
            />
            <input
              {...register('vcode_3', { required: true })}
              tabIndex={2}
              onKeyUp={handleOtp}
              onPaste={handleOtpPaste}
              type="tel"
              autocomplete="off"
              maxLength="1"
              className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                otpError ? 'border-danger' : ''
              }`}
            />
            <input
              {...register('vcode_4', { required: true })}
              tabIndex={3}
              onKeyUp={handleOtp}
              onPaste={handleOtpPaste}
              type="tel"
              autocomplete="off"
              maxLength="1"
              className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                otpError ? 'border-danger' : ''
              }`}
            />
            <input
              {...register('vcode_5', { required: true })}
              tabIndex={4}
              onKeyUp={handleOtp}
              onPaste={handleOtpPaste}
              type="tel"
              autocomplete="off"
              maxLength="1"
              className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                otpError ? 'border-danger' : ''
              }`}
            />
            <input
              {...register('vcode_6', { required: true })}
              tabIndex={5}
              onKeyUp={handleOtp}
              onPaste={handleOtpPaste}
              type="tel"
              autocomplete="off"
              maxLength="1"
              className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                otpError ? 'border-danger' : ''
              }`}
            />
          </div>
          {isDisabled && (
            <small className="text-success">
              {t('Text-MessageSentSuccessfully', { ns: 'global' })}
            </small>
          )}
          {errors.vcode_1 && (
            <small className="text-danger">
              {t(`${errors.vcode_1.message}`, { ns: 'global' })}
            </small>
          )}
          <TimerButton
            isDisabled={isDisabled}
            onClick={sendToken}
            expires={expires}
            buttonText={t('validation.Text-GetCode', { ns: 'modules' })}
          />
        </Row>
        <div className="d-flex justify-content-center mt-75 mb-3">
          <JpButton
            type="save"
            buttonType="submit"
            text={t('button.Btn-Verify')}
            color="primary"
            options={{ iconless: true }}
          />
        </div>
      </Form>
    </Modal>
  )
}

export default TwoFactorModal
