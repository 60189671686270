// ** React Imports
import { useState } from 'react'

// ** Reactstrap Imports
import { Button, ListGroup, ListGroupItem, Row, Col } from 'reactstrap'

// ** Third Party Imports
import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'
import { X, FileText } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { uploadLegalFile } from '../../http-common'
import { useMutation } from '@tanstack/react-query'
import APIService from '@src/services/API'
import { JpButton } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'

const Document = ({
  stepper,
  data,
  setWentBack,
  currentIndex,
  finishInvite,
  isLegalRepresentative
}) => {
  const API = new APIService()

  // ** State
  const [files, setFiles] = useState([])
  const { t } = useTranslation()

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        toast.error('You can only upload PDFs (max 50MB)!')
      } else {
        setFiles([...files, ...acceptedFiles.map(file => Object.assign(file))])
      }
    },
    maxSize: 52428800
  })

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} MB`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} KB`
    }
  }

  const fileList = files.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center text-truncate">
        <div className="file-preview me-1">
          {/* <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' /> */}
          <FileText size="28" />
        </div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
          <p className="file-size mb-0">{renderFileSize(file.size)}</p>
        </div>
      </div>
      <Button
        color="danger"
        outline
        size="sm"
        className="btn-icon ms-75"
        onClick={() => handleRemoveFile(file)}
      >
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))

  const skipVeriff = () => {
    if (!!data?.hasBeenVerified) {
      finishInvite()
    }
  }

  const uploadMutation = useMutation(data => API.uploadDocument(data), {
    onSuccess: response => {
      setWentBack(false)
      stepper.next(currentIndex)
      skipVeriff()
    },
    onError: e => {
      console.log(e)
    }
  })

  const handleUpload = () => {
    uploadMutation.mutate({ file: files[0] })
    // stepper.next()
  }

  return (
    <>
      <div className="register-content d-flex flex-column justify-content-center">
        <div className="content-header text-center mb-0">
          <span>{t('title.Title-Welcome', { ns: 'modules' })}</span>
          <h3 className="fw-bolder mb-75">
            {t('title.Title-UploadLegalDocument', { ns: 'modules' })}
          </h3>
        </div>
        {!files.length > 0 && (
          <div
            {...getRootProps({
              className: 'dropzone align-items-center'
            })}
          >
            <input id="template" {...getInputProps()} />
            <div className="d-flex align-items-center justify-content-center flex-column px-5 py-2 border rounded">
              <FontAwesomeIcon icon={faCloudArrowUp} size="4x" />
              <p className="fw-bolder mb-75">
                {t('legal.TextLegalDocumentUpload', { ns: 'modules' })}
              </p>
              <p className="text-secondary">
                {t('legal.TextLegalDocumentDrop', { ns: 'modules' })}
                <a href="/" onClick={e => e.preventDefault()}>
                  {t('legal.TextLegalDocumentBrowse', { ns: 'modules' })}
                </a>{' '}
                {t('legal.TextLegalDocumentMachine', { ns: 'modules' })}
              </p>
            </div>
          </div>
        )}
        {files.length > 0 && (
          <Row className="justify-content-center">
            <Col md="6">
              <ListGroup className="mb-1">{fileList}</ListGroup>
            </Col>
          </Row>
        )}
      </div>
      <div
        className={`d-flex justify-content-${
          !isLegalRepresentative ? 'between' : 'end'
        } mt-2`}
      >
        {!isLegalRepresentative && (
          <JpButton
            type="back"
            options={{
              onClick: () => {
                stepper.to(currentIndex)
                setWentBack(true)
              },
              iconPosition: 'left'
            }}
          />
        )}
        <div>
          <JpButton
            type="skip"
            options={{
              onClick: () => {
                stepper.next()
                setWentBack(false)
                skipVeriff()
              },
              iconless: true
            }}
            className="me-75"
          />
          {files.length ? (
            <JpButton
              type="next"
              loading={uploadMutation?.isLoading}
              options={{ onClick: handleUpload }}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default Document
