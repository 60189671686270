import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { ChevronRight } from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import * as yup from 'yup'
import { SpinnerLoader, JpButton } from '@Intelli/utilities'

const LegalConfirmation = ({
  index,
  currentIndex,
  setCurrentIndex,
  data,
  stepper,
  registerData,
  setLegalRepresentative,
  setHasConfirmed
}) => {
  const { t } = useTranslation()

  const SignupSchema = yup.object().shape({
    legal_representative: yup
      .boolean()
      .oneOf([true, false], t('legal_representative.required'))
  })

  const defaultValues = {
    legal_representative: data?.isLegalRepresentative || false
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema)
  })

  const onSubmit = () => {
    setHasConfirmed(true)
    stepper.next()
  }

  if (!registerData || !data) {
    return <SpinnerLoader />
  }

  return (
    <>
      <div className="register-content d-flex flex-column justify-content-center">
        <div className="content-header mb-2 text-center">
          <h2 className="fw-bolder mb-75">
            {t('title.Title-AccountInformation', { ns: 'modules' })}
          </h2>
          <span>{t('legal.TextLegalDetails', { ns: 'modules' })}</span>
          <div>
            <h6>{`${data?.user?.first_name} ${data?.user?.last_name}`}</h6>
          </div>
        </div>
        <Row
          tag={Form}
          onSubmit={handleSubmit(onSubmit)}
          className="form-switch form-check-info p-0 mb-2 align-self-bottom text-center justify-content-center"
        >
          <Col md="2" className="p-0">
            <Label
              className="form-label align-middle m-0"
              for={'legal_representative'}
            >
              {t('legal.TextLegalConfirmation', { ns: 'modules' })}
            </Label>
          </Col>
          <Controller
            name="legal_representative"
            control={control}
            render={({ field: { onChange } }) => (
              <Input
                className="ms-0"
                type="switch"
                id={'legal_representative'}
                defaultChecked={data?.isLegalRepresentative}
                onClick={e => {
                  setLegalRepresentative(e.target.checked)
                  onChange(e)
                }}
              />
            )}
          />
          <FormFeedback invalid>
            {errors.legal_representative?.message}
          </FormFeedback>
        </Row>
      </div>
      <div className={'d-flex justify-content-end'}>
        <JpButton
          type="next"
          className="text-end"
          options={{
            onClick:
              // setHasConfirmed(true)
              // console.log('next')
              // stepper.next()
              handleSubmit(onSubmit)
          }}
        />
      </div>
    </>
  )
}

export default LegalConfirmation
