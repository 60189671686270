import APIService from '@src/services/API'
import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SpinnerLoader, GeoLocation } from '@Intelli/utilities'
import RegisterAdmin from './RegisterAdmin'
import RegisterWithInvite from './RegisterWithInvite'
import RegisterContractor from './RegisterContractor'


const Register = () => {
  const API = new APIService()

  const geoLocation = GeoLocation()

  // ** Hooks
  const navigate = useNavigate()

  const { mutate, data, isLoading, isError } = useMutation(
    token => API.ValidateInvitationToken(token),
    {
      onSuccess: response => {
        if (
          response.flow !== 'backoffice_invitation' &&
          response.flow !== 'admin_invitation' &&
          response.flow !== 'contractor_invitation' && 
          response.flow !== 'client_invitation'
        ) {
          API.logout()
          localStorage.clear()
          navigate('/auth/login')
        }
        localStorage.setItem('token', JSON.stringify(response?.credential?.token))
      },
      onError: e => {
        console.log(e)
      }
    }
  )
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    sessionStorage.setItem('invite_token', searchParams.get('token'))
    mutate(searchParams.get('token'))
  }, [])

  if (isLoading || isError || !data) {
    return <SpinnerLoader />
  }
  if (data.flow === 'admin_invitation' || data.flow === 'client_invitation') {
    return (
      <RegisterAdmin
        data={data}
        isLegalRepresentative={data.isLegalRepresentative}
        location={geoLocation?.address}
        mutate={()=>{mutate(searchParams.get('token'))}}
      />
    )
  } else if (data.flow === 'backoffice_invitation') {
    return <RegisterWithInvite data={data} location={geoLocation?.address}/>
  } else if (data.flow === 'contractor_invitation') {
    return <RegisterContractor data={data} location={geoLocation?.address}/>
  } else {
    return <SpinnerLoader />
  }
}

export default Register
