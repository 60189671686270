import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './rootReducer'

const transform = createTransform(
  null,
  (state, key) => {
    /* console.log('key', key)
    console.log('state', state) */
    return state
  }
)

const persistConfig = {
  key: 'backoffice',
  version: 1,
  transforms: [transform],
  blacklist: ['layout'],
  whitelist: ['navbar', 'breadcrumbs', 'login', 'email'],
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

let persistor = persistStore(store)

export {store, persistor}