import logo from '../assets/images/logo/logo_white_horizontal.svg'

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  Button,
  UncontrolledTooltip,
  CardFooter,
  CardText
} from 'reactstrap'
import { ChevronRight } from 'react-feather'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import APIService from '@src/services/API'
import Swal from 'sweetalert2'
import TwoFactorModal from '@src/components/TwoFactorModal'
import { useTranslation } from 'react-i18next'
import { JpAvatar, SpinnerLoader, user$ } from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPowerOff } from '@fortawesome/pro-solid-svg-icons'
import { Fragment } from 'react'
const CompanyPicker = () => {
  const API = new APIService()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [companies, setCompanies] = useState([])
  const [tfaModalOn, setTFAModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showErrorLogo, setShowErrorLogo] = useState(false)

  const {
    data: userData,
    isError: userHasError,
    isLoading: userIsLoading,
    error: userError,
    refetch
  } = useQuery(['user-access'], () => API.userAccess())

  const { mutate: changeAdminWorkspace } = useMutation(
    workspace => API.changeAdminWorkspace(workspace[0]),
    {
      onSuccess: (data, vars, context) => {
        localStorage.setItem('token', JSON.stringify(data.token.token))
        if (vars.verification_legal_required === true) {
          Swal.fire({
            title: t('You are now a legal representant'),
            text: t(
              'Would you please fill some additional info for the new role?'
            ),
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: t('Skip'),
            showConfirmButton: true,
            confirmButtonText: t('Continue'),
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-secondary'
            },
            buttonsStyling: false
          }).then(value => {
            if (value.isConfirmed) {
              window.location.href = '/auth/legal-representative/'
              /* navigate('/auth/legal-representative/') */
            }
          })
        } else {
          if (!!vars[1]?.redirect) {
            window.location.href = vars[1]?.redirect
            // navigate(vars[1]?.redirect)
          } else {
            window.location.href = '/admin'
            /* navigate('/admin') */
          }
        }
      },
      //Verificar el error del logout y mandarlo al login con los params originales para que se loggee y llegue a admin a la ruta deseada
      /* onError: error => {
        if (error.response.data.code === 'VLMD003') {
          const url = new URLSearchParams(window.location.search)
          const newUrl = `/auth/login?logout=ok&${url.toString()}`
          ;async () => {
            API.logout()
          }
          setTimeout(() => (window.location.href = newUrl), 1000)
        }
      } */
    }
  )

  const { mutate: changeContractorWorkspace } = useMutation(
    workspace => API.changeContractorWorkspace(workspace[0]),
    {
      onSuccess: (data, vars, context) => {
        localStorage.setItem('token', JSON.stringify(data.token.token))
        if (!!vars[1]?.redirect) {
          window.location.href = vars[1]?.redirect
        } else {
          window.location.href = '/wallet'
        }

      }
    }
  )

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      window.location.href = '/'

    }

    if (!userIsLoading && !userHasError) {
      if (userData?.has_two_factor === false) {
        setTFAModal(true)
      } else {
        setTFAModal(false)
        if (userData?.access) {
          if (!!searchParams.get('redirect')) {
            const redirect = searchParams.get('redirect')
            const workspace_id = searchParams.get('workspace_id')
            user$.next({
              backoffice: userData.access.backoffice,
              company: userData.access?.clients.find(
                company =>
                  company?.admin?.workspace_id === workspace_id ||
                  company?.contractor?.workspace_id === workspace_id
              ),
              companiesCount: userData.access.clients.length
            })

            if (redirect.includes('admin')) {
              if (!!workspace_id) {
                changeAdminWorkspace([
                  { access_id: workspace_id },
                  { redirect }
                ])
              }
            } else if (redirect.includes('wallet')) {
              if (!!workspace_id) {
                changeContractorWorkspace([{ workspace_id }, { redirect }])
              }
            } else {
              window.location.href = searchParams.get('redirect')
            }
          } else {
            if (
              userData?.access?.backoffice === true &&
              searchParams.get('auto') !== 'false' &&
              searchParams.get('workspace_id') !== 'false'
              //Pasar directo a BO si no tiene redirect a admin ni workspace_id
            ) {
              if (userData?.access?.clients.length > 0) {
                user$.next({
                  backoffice: true,
                  company: userData.access.clients[0],
                  companiesCount: userData.access.clients.length
                })
              } else {
                user$.next({ backoffice: true })
              }
              /* navigate(`/backoffice`) */
              window.location.href = `/backoffice${
                searchParams.get('redirect') && searchParams.get('redirect').includes('backoffice') ?
                `?${searchParams.get('redirect')}` : ''
              }`
            } else if (userData?.access?.clients.length > 0) {
              setCompanies(userData.access.clients)
              if (
                userData?.access?.clients?.length === 1 &&
                searchParams.get('auto') !== 'false'
              ) {
                handleCompanyChange(userData.access.clients[0])
              }
            } else if (
              userData?.access?.backoffice === false &&
              userData?.access?.clients.length === 0
            ) {
              window.location.href = '/wallet'
              /* navigate('/wallet') */
              user$.next({
                backoffice: userData.access.backoffice,
                companiesCount: 0
              })
            }
          }
        }
      }
    }
  }, [userIsLoading, userHasError, userData, userError, navigate])

  const handleCompanyChange = company => {
    user$.next({
      backoffice: userData.access.backoffice,
      company,
      companiesCount: userData.access.clients.length
    })
    if (company.admin) {
      changeAdminWorkspace([{ access_id: company.admin.access_id }, company])
    } else if (company.contractor) {
      changeContractorWorkspace([
        { contractor_id: company.contractor.contractor_id },
        company
      ])
    }
  }

  if (userIsLoading || userHasError) {
    return (
      <div>
        <Row className="mb-5 justify-content-between align-items-center mt-2 ms-2 me-1">
          <Col md="4">
            <img
              src={logo}
              alt="logo"
              className="logo text-center"
              style={{ maxWidth: '250px' }}
            />
          </Col>
          <Col md="2" className="text-end">
            <UncontrolledTooltip placement="bottom" target="logout">
              {t('login.logout', { ns: 'modules' })}
            </UncontrolledTooltip>
            <Button
              id="logout"
              color="link"
              onClick={() => {
                API.logout()
              }}
            >
              <FontAwesomeIcon icon={faPowerOff} size="2x" />
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <SpinnerLoader />
        </Row>
      </div>
    )
  }
  return (
    <>
      {companies?.length === 1 ? (
        <SpinnerLoader />
      ) : (
        <>
          <TwoFactorModal isModalOpen={tfaModalOn} refetch={refetch} />
          <div className="auth-wrapper auth-cover  d-flex justify-content-center">
            <Row className="auth-inner m-0">
              <div className="brand-logo justify-content-between">
                <img style={{ maxWidth: '250px' }} src={logo} alt="logo" />
                <UncontrolledTooltip placement="bottom" target="logout">
                  {t('login.logout', { ns: 'modules' })}
                </UncontrolledTooltip>
                <Button
                  id="logout"
                  color="link"
                  className="me-5"
                  onClick={() => {
                    API.logout()
                  }}
                >
                  <FontAwesomeIcon icon={faPowerOff} size="2x" />
                </Button>
              </div>
              <Col lg="3" className="d-none d-lg-flex align-items-center p-0">
                <div className="w-100 d-lg-flex align-items-center justify-content-center lobby-img" />
              </Col>
              {companies.length > 0 && (
                <Col className="d-flex justify-content-center align-items-center">
                  <Card className="w-50 ps-0 pe-0 rounded-3 mb-3">
                    <CardHeader className="justify-content-center pt-4 pb-1">
                      <CardTitle>
                        <h1>{t('title.SelectCompany', { ns: 'modules' })}</h1>
                      </CardTitle>
                    </CardHeader>

                    <CardBody className="p-0 pb-2">
                      {companies?.map((company, index) => {
                        return (
                          <Fragment key={index}>
                            <Button
                              key={company.id}
                              type="button"
                              color="link"
                              onClick={() => handleCompanyChange(company)}
                              className="w-100 p-0 company-row"
                            >
                              <Row className="text-reset p-2 ps-4 pe-4 align-items-center justify-content-lg-between justify-content-sm-center company-text">
                                {!showErrorLogo && company?.logo ? (
                                  <img
                                    src={company?.logo}
                                    alt="logo"
                                    className="logo rounded-circle border p-1 bg-white dashboard_img"
                                    style={{ width: '70px', height: '70px' }}
                                    onError={e => {
                                      setShowErrorLogo(true)
                                    }}
                                  />
                                ) : (
                                  <Col className="text-start ps-0">
                                    {/* <FontAwesomeIcon
                                icon={faBuilding}
                                size="5x"
                                className="logo"
                              /> */}
                                    <JpAvatar
                                      color={`light-info`}
                                      content={company?.name}
                                      size="xl"
                                      initials
                                    />
                                  </Col>
                                )}
                                <Col className="d-none d-lg-flex justify-content-end align-items-center">
                                  <span className="m-75 align-middle">
                                    {company?.name}
                                  </span>
                                  <ChevronRight />
                                </Col>
                              </Row>
                            </Button>
                            {index !== companies.length - 1 && (
                              <hr className="divider divider-secondary m-0"></hr>
                            )}
                          </Fragment>
                        )
                      })}
                    </CardBody>
                    <CardFooter>
                      <CardText className="text-center">
                        {t('title.Subtitle-Lobby', { ns: 'modules' })}
                      </CardText>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </>
      )}
    </>
  )
}

export default CompanyPicker
